import React, { useState } from 'react';
import { useRefs } from '../../Pages/RefsContext'
const Faqs = () => {
    const [expandedItem, setExpandedItem] = useState(null);

    const toggleItem = (item) => {
        setExpandedItem(expandedItem === item ? null : item);
    };
    const { faqRef } = useRefs();
    return (
        <>
            {/* Faqs Section Start */}
            <section ref={faqRef} className="zm_faq">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="zm_faq-content">
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-7 col-md-10">
                                        <div data-aos="fade-up" data-aos-duration="3000" className="zm_section-title text-center mb-60">
                                            <span className="zm_sub-title zm_animate-text">Get your answer</span>
                                            <h3 className="title">Frequently asked questions</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="zm_faq-wrapper">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading1">
                                                <button
                                                    className={`accordion-button ${expandedItem === 1 ? '' : 'collapsed'}`}
                                                    type="button"
                                                    onClick={() => toggleItem(1)}
                                                    aria-expanded={expandedItem === 1}
                                                    aria-controls="collapse1"
                                                >
                                                    <span className="count">1</span> What is Zeb Token?
                                                </button>
                                            </h2>
                                            <div
                                                id="collapse1"
                                                className={`accordion-collapse collapse ${expandedItem === 1 ? 'show' : ''}`}
                                                aria-labelledby="heading1"
                                            >
                                                <div className="accordion-body">
                                                    Zeb Token is our decentralized digital asset designed to reward users
                                                    for their engagement with the ZebMoney ecosystem. It can be swapped,
                                                    staked, and offers various utilities.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading2">
                                                <button
                                                    className={`accordion-button ${expandedItem === 2 ? '' : 'collapsed'}`}
                                                    type="button"
                                                    onClick={() => toggleItem(2)}
                                                    aria-expanded={expandedItem === 2}
                                                    aria-controls="collapse2"
                                                >
                                                    <span className="count">2</span> How can I earn Zeb Tokens?
                                                </button>
                                            </h2>
                                            <div
                                                id="collapse2"
                                                className={`accordion-collapse collapse ${expandedItem === 2 ? 'show' : ''}`}
                                                aria-labelledby="heading2"
                                            >
                                                <div className="accordion-body">
                                                    Users can earn Zeb Tokens through the AD rewards, by staking, or
                                                    Swapping.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading3">
                                                <button
                                                    className={`accordion-button ${expandedItem === 3 ? '' : 'collapsed'}`}
                                                    type="button"
                                                    onClick={() => toggleItem(3)}
                                                    aria-expanded={expandedItem === 3}
                                                    aria-controls="collapse3"
                                                >
                                                    <span className="count">3</span> How does the staking mechanism work?
                                                </button>
                                            </h2>
                                            <div
                                                id="collapse3"
                                                className={`accordion-collapse collapse ${expandedItem === 3 ? 'show' : ''}`}
                                                aria-labelledby="heading3"
                                            >
                                                <div className="accordion-body">
                                                    We offer various types of staking pools with different time commitments
                                                    and reward rates. With no withdrawal fees.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading4">
                                                <button
                                                    className={`accordion-button ${expandedItem === 4 ? '' : 'collapsed'}`}
                                                    type="button"
                                                    onClick={() => toggleItem(4)}
                                                    aria-expanded={expandedItem === 4}
                                                    aria-controls="collapse4"
                                                >
                                                    <span className="count">4</span> Is ZebMoney secure?
                                                </button>
                                            </h2>
                                            <div
                                                id="collapse4"
                                                className={`accordion-collapse collapse ${expandedItem === 4 ? 'show' : ''}`}
                                                aria-labelledby="heading4"
                                            >
                                                <div className="accordion-body">
                                                    Absolutely. ZebMoney employs advanced encryption methods and undergoes
                                                    regular audits to ensure the utmost security for your data and assets.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading5">
                                                <button
                                                    className={`accordion-button ${expandedItem === 5 ? '' : 'collapsed'}`}
                                                    type="button"
                                                    onClick={() => toggleItem(5)}
                                                    aria-expanded={expandedItem === 5}
                                                    aria-controls="collapse5"
                                                >
                                                    <span className="count">5</span> How do I set up my wallet on Polygon chain?
                                                </button>
                                            </h2>
                                            <div
                                                id="collapse5"
                                                className={`accordion-collapse collapse ${expandedItem === 5 ? 'show' : ''}`}
                                                aria-labelledby="heading5"
                                            >
                                                <div className="accordion-body">
                                                    <p>Trust Wallet: When you go into the dApps in the top-right corner you can
                                                        see the different blockchains available, click on it then choose Polygon
                                                        Chain Mainnet.</p>
                                                    <p>MetaMask: Create a custom RPC with the following Polygon
                                                        Mainnet in the Network Name field, URL https://polygon-rpc.com/ in the
                                                        New RPC URL field, 137 in Chain ID field, MATIC in Currency Symbol field
                                                        and https://polygonscan.com/ in Block Explorer URL field.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Faqs Section End */}
        </>
    )
}

export default Faqs