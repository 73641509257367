import { providers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPublicClient, formatUnits, http } from "viem";
import SwapAbi from "./Abi.json"
export const swapContract = "0x4B4F910214CFC0498Bc1f8040DBcD2Ca397cA456" // Mainnet


export const chainRPC = "https://virulent-soft-glade.matic.quiknode.pro/f31fd1dab926f81b6236cd10e84ced895dd6ddf7/"  // Mainnet link
export const web3Provider = new providers.JsonRpcProvider(chainRPC);



export function SwapRate() {
    const [buyPrice, setbuyPrice] = useState(0);

    const fetchHFGTokenInfo = useCallback(async () => {
        try {

            const client = createPublicClient({
                chain: polygon,
                transport: http(),
            });
            const data = await client.multicall({
                contracts: [
                    {
                        address: swapContract,
                        abi: SwapAbi,
                        functionName: 'SwapTokens',
                        args: [1],
                    },
                ],
                allowFailure: false
            })

            if (data?.length > 0) {
                setbuyPrice(formatUnits(data[0][1], 4))
            }

        } catch (err) {
            console.error(err)
        }
    }, [])


    useEffect(() => {
        const interval = setInterval(async () => {
            fetchHFGTokenInfo()
        }, 20000);
        return () => clearInterval(interval);
    }, [fetchHFGTokenInfo]);

    useEffect(() => {
        fetchHFGTokenInfo();
    }, [fetchHFGTokenInfo])

    return useMemo(() => {
        return {
            buyPrice,
        }
    }, [buyPrice])

}



const polygon = {
    id: 137,
    name: "Polygon",
    network: "matic",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ["https://polygon-mainnet.g.alchemy.com/v2"],
            webSocket: ["wss://polygon-mainnet.g.alchemy.com/v2"],
        },
        infura: {
            http: ["https://polygon-mainnet.infura.io/v3"],
            webSocket: ["wss://polygon-mainnet.infura.io/ws/v3"],
        },
        default: {
            http: ["https://virulent-soft-glade.matic.quiknode.pro/f31fd1dab926f81b6236cd10e84ced895dd6ddf7/"],
        },
        public: {
            http: ["https://virulent-soft-glade.matic.quiknode.pro/f31fd1dab926f81b6236cd10e84ced895dd6ddf7/"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
        default: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 25770160,
        },
    },
};